/* ===================== 通用样式 ==================== */
body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  margin: 50px;
  padding: 100px;
  background-color: rgb(244, 244, 244);
  color: #000000; /* 全局字体颜色为白色 */
  text-align: center; /* 文本居中对齐 */
  line-height: 1.6; /* 行高 */
}
body.dark-mode {
  background-color: rgb(18,18,18); /* 黑色背景 */
  color: #fff; /* 白色字体 */
}

h2 {
  font-size: 30px;
}
h1, h2 {
  margin: 20px 0; /* 标题间距 */
}
body.light {
  --bg-color: white;
  --text-color: #111;
}

body.dark {
  --bg-color: #111;
  --text-color: white;
}

body {
  background-color: var(--bg-color);
  color: var(--text-color);
  transition: background 0.3s ease, color 0.3s ease;
}



/* ======================= 导航栏样式 ================= */
/* 导航栏样式 */
nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px;
  background: rgb(244, 244, 244,0.8); /* 背景色 */
  padding: 0px 10px;
  display: flex; /* Flexbox 布局 */
  justify-content: space-between; /* 左右对齐 */
  align-items: center; /* 垂直居中 */
  z-index: 1000;
}

body.dark-mode nav {
  background: rgb(18,18,18,0.8); /* 黑色背景 */
}

/* 左侧 Logo 和按钮 */
.nav-left {
  display: flex;
  align-items: center; /* 垂直居中 */
}

.logo {
  width: 150px; /* Logo 大小 */
  height: 150px;
}

.theme-toggle {
  background: none; /* 无背景 */
  border: none; /* 无边框 */
  color: #fff; /* 按钮颜色 */
  font-size: 2rem; /* 字体大小 */
  cursor: pointer; /* 鼠标样式 */
  transition: transform 0.3s ease; /* 动画效果 */
}

.theme-toggle:hover {
  transform: rotate(30deg); /* 悬停旋转 */
}

/* 右侧导航链接 */
.nav-links {
  display: flex; /* 横向排列 */
  list-style: none; /* 去掉默认列表样式 */
  gap: 20px; /* 每个导航链接的间距 */
  margin-right: 20px; /* 右侧导航链接距离右侧边距 */
}

.nav-links li a {
  color: rgb(78, 76, 76); /* 文字颜色 */
  padding: 10px 20px; /* 内边距 */
  text-decoration: none; /* 去掉下划线 */
  /*border: 2.5px solid rgb(78, 76, 76); /* 初始透明边框 */
  border-radius: 30px; /* 圆角 */
  background-color: transparent; /* 默认透明背景 */
  font-weight: bold; /* 字体加粗 */
  transition: background-color 0.3s ease, color 0.3s ease; /* 平滑过渡效果 */
  box-shadow: 0 0 8px 2px silver, 0 0 12px 4px white; /* 添加发光阴影 */
}
body.dark-mode .nav-links li a {
  color: #ffffff; /* 白色文字 */
  box-shadow: 0 0 8px 2px silver, 0 0 12px 4px gray; /* 夜间的发光效果 */
}
.nav-links li a:hover {
  background-color: rgb(78, 76, 76); /* 悬停时背景变黑 */
  color: #fff; /* 悬停时文字变白 */
  transform: scale(1.05); /* 悬停时稍微放大按钮 */
}
body.dark-mode .nav-links li a:hover {
  background-color: #ffffff; /* 悬停时背景变浅 */
  color: #000000; /* 悬停时文字变深 */
}




body.dark-mode .container {
  background-color: rgba(25, 25, 25, 0.8); /* 黑色半透明背景 */
}


/* 主容器 */
.home-container {
  display: flex;
  flex-direction: column; /* 默认竖直布局 */
  align-items: center;
  gap: 20px; /* 间距 */
}




/* ====================== INTRO ===============*/

/* 第一部分：头像 */
.profile-section {
  display: flex;
  justify-content: center;
  align-items: center;
}
.profile-image img {
  width: 200px;
  height: 200px;
  animation: bounce 2s infinite; /* 添加动画 */
}
/* 动画效果 */
@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

/* 招小手 */
@keyframes waveHand {
  0% { transform: rotate(0deg); }
  15% { transform: rotate(20deg); }
  30% { transform: rotate(-15deg); }
  45% { transform: rotate(15deg); }
  60% { transform: rotate(-10deg); }
  75% { transform: rotate(10deg); }
  100% { transform: rotate(0deg); }
}

#wave-emoji.waving {
  display: inline-block;
  animation: waveHand 1s ease;
  transform-origin: bottom center;
}


/* 介绍文字 */
.introduction-section {
  text-align: center;
}
.introduction-section h1 {
  font-size: 2.5rem;
  font-weight: bold;
}
.introduction-section h2 {
  font-size: 1.5rem;
  color: #555;
}
body.dark-mode .introduction-section h2 {
  color: #e0dddd; /* 白色文字 */
}
.introduction-section p {
  font-size: 1rem;
  margin: 10px 0;
}

/* intro的typing 效果 (class = typing) */

/* current interest的hover脑图 */
.node {
  cursor: pointer;
  position: relative;
  font-weight: bold;
  color: #333;
}

.popup-branch {
  position: absolute;
  background: #fff;
  border: 1px solid #ccc;
  padding: 8px 12px;
  border-radius: 10px;
  display: none;
  font-size: 0.9rem;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  z-index: 999;
}

.node:hover + #branch-ai {
  display: block;
  top: 40px;
  left: 120px;
}

/* 第三部分：社交链接和简历按钮 */
.social-resume-section {
  display: flex;

  justify-content: center;
  gap: 15px;
}

.social-icon {
  height: 50px; /* 统一高度 */
  width: 50px; /* 图标的宽度与高度一致，按钮会自动适配文字 */
  font-size: 2rem;
  color: #000;
  text-decoration: none;
  display: inline-flex; /* 确保内容居中对齐 */
  align-items: center;
  justify-content: center;
}
/* 夜间模式社交图标样式 */
body.dark-mode .social-icon {
  color: #ffffff; /* 浅色图标 */
}

.social-icon:hover {
  transform: scale(1.6); /* 鼠标悬停时放大 */
  transform: rotate3d(1, 1, 1, 30deg); /* 3D旋转 */
  transition: transform 0.3s ease; /* 平滑过渡 */
}

/* Resume 按钮样式 */
.resume-button {
  text-decoration: none; /* 去掉下划线 */
  padding: 0 20px; /* 内边距适配文字 */
  background-color: #000; /* 按钮背景色 */
  color: #fff;
  font-size: 1rem; /* 按钮文字大小 */
  display: inline-flex;
  align-items: center;
  gap: 8px; /* 文字和图标的间距 */
  border-radius: 8px;
}
body.dark-mode .resume-button {
  background-color: #ffffff; /* 白色按钮 */
  color: #000000; /* 黑色文字 */
}
.resume-button span {
  margin-right: 8px; /* 文字与图标之间的距离 */
}

.resume-button i {
  font-size: 1.2rem; /* 图标大小 */
  transition: transform 0.3s ease; /* 平滑过渡 */
}

/* 悬停效果，只有图标旋转 */
.resume-button:hover i {
  font-size: 1.5rem;
  transform: rotate(45deg);
}

.resume-button:hover {
  background-color: #747474; /* 按钮背景变色 */
}




/* ============================================= */
/* =============== sections 部分样式 ============ */
/* ============================================= */

.section {
  padding: 40px 0; /* 内边距 */
  opacity: 0; /* 默认透明 */
  transform: translateY(30px); /* 下移 */
  transition: opacity 0.6s ease, transform 0.6s ease; /* 动画过渡 */
}

.section.visible {
  opacity: 1; /* 进入视口时显示 */
  transform: translateY(0); /* 移回原位 */
}



/* ================ About ================ */

/* 引号 */
.quote-block {
  position: relative;
  padding: 40px 30px;
  font-style: italic;
  background: rgba(255, 255, 255, 0.4); 
  border-radius: 12px;
  backdrop-filter: blur(4px);
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.05);
  color: #444;
  z-index: 1;
  transition: all 0.3s ease;
}

.quote-block::before,
.quote-block::after {
  content: "“";
  font-size: 100px;
  font-family: Georgia, serif;
  color: rgba(150, 0, 0, 0.2);
  position: absolute;
  z-index: 0;
}

.quote-block::before {
  top: -30px;
  left: -20px;
}

.quote-block::after {
  content: "”";
  bottom: -30px;
  right: -20px;
  transform: scaleX(1);
}

/* 🌙 黑夜模式适配 */
body.dark-mode .quote-block {
  background: rgba(255, 255, 255, 0.06);
  color: #f3f3f3;
  box-shadow: 0 0 24px rgba(255, 50, 50, 0.1);
}

body.dark-mode .quote-block::before,
body.dark-mode .quote-block::after {
  color: rgba(255, 100, 100, 0.3);
}


.bio-with-photo {
  display: flex;
  justify-content: space-between;
  align-items: center;      /* ✅ 改为垂直居中对齐 */
  gap: 48px;
  margin-top: 20px;
  flex-wrap: wrap;
}

.bio-text {
  flex: 1;
  min-width: 300px;
}

.bio-photo img {
  max-width: 280px;        /* ✅ 限制最大宽度 */
  max-height: 220px;       /* ✅ 限制最大高度 */
  border-radius: 12px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  object-fit: cover;
}
@media screen and (max-width: 768px) {
  .bio-with-photo {
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }

  .bio-photo img {
    width: 90%;
    max-height: none;
  }
}
.bio-photo img:hover {
  transform: scale(1.03);
  transition: 0.3s ease;
}


/* Track 样式 */
.stage-track {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin: 30px 0;
  padding-top: 30px;
  animation: fadein 1s ease-in-out;
}

.stage-box {
  background-color: #000;
  color: #fff;
  padding: 8px 16px;
  border-radius: 12px;
  font-family: "Fira Code", monospace;
  font-size: 1rem;
  opacity: 0;
  transform: scale(0.8);
  animation: rise 0.4s ease forwards;
}
.stage-box:nth-child(1) { animation-delay: 0.2s; }
.stage-box:nth-child(3) { animation-delay: 0.6s; }
.stage-box:nth-child(5) { animation-delay: 1s; }

.stage-arrow {
  font-size: 1.5rem;
  color: #888;
  animation: blink 1.5s infinite ease-in-out;
}

.stage-box.final {
  background: linear-gradient(135deg, #e8e4e8, #c1041e);
  box-shadow: 0 0 12px rgba(255, 100, 150, 0.6);
  animation: blink 2.5s infinite ease-in-out;
}

@keyframes aiBreath {
  0%, 100% {
    box-shadow: 0 0 12px rgba(255, 100, 150, 0.6),
                0 0 24px rgba(255, 100, 150, 0.3);
    transform: scale(1);
  }
  50% {
    box-shadow: 0 0 20px rgba(255, 100, 150, 0.9),
                0 0 40px rgba(255, 0, 80, 0.5);
    transform: scale(1.08);
  }
  
}


/* 动画关键帧 */
@keyframes fadein {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}
@keyframes rise {
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes blink {
  0%, 100% { opacity: 0.4; transform: scale(1); }
  50% { opacity: 1; transform: scale(1.3); }
}


/* 教育经历 */
.edu-timeline {
  margin-top: 40px;
  padding-left: 60px;
  border-left: 3px solid #ccc;
  display: flex;
  flex-direction: column;
  gap: 18px;
}
.edu-item {
  position: relative;
  padding-left: 16px;
  animation: fadein 1s ease;
}
.edu-item::before {
  content: "🎓";
  position: absolute;
  left: -26px;
  top: 0;
}





/* ================== skills 部分样式 ==================== */
.skills {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 两列布局 */
  gap: 30px;
  max-width: 86%; /* 限制最大宽度 */
  margin:0 auto; /* 居中对齐 */
}

.card {
  background: rgba(255, 255, 255, 0.1); /* 半透明背景 */
  padding: 20px;
  margin: 30px;
  border-radius: 10px; /* 圆角 */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3), 0 8px 24px rgba(0, 0, 0, 0.2); /* 深化阴影 */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* 平滑过渡 */
}

/* 夜间模式技能卡片 */
body.dark-mode .card {
  background: rgba(255, 255, 255, 0.05); /* 更深的透明背景 */
  color: #ffffff; /* 白色文字 */
  box-shadow: 0 4px 10px rgba(255, 255, 255, 0.2); /* 浅色阴影 */
}


.card:hover {
  transform: scale(1.05) rotate(1deg); /* 放大并旋转 */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3); /* 阴影增加 */
}

.card h3 {
  margin-bottom: 15px;
  font-size: 1.2rem;
}
/* 环形图样式 */

.circle-chart {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  margin-bottom: 40px; /* 整个图标区域与卡片底部的额外间距 */
}

.circle {
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: conic-gradient(
    #e0e0e0 0deg,
    #e0e0e0 360deg
  ); /* 初始全灰色 */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.5rem;
  text-align: center;
  color: #333; /* 黑色文字 */
}

body.dark-mode .circle {
  background: conic-gradient(
    #333 0deg,
    #333 360deg
  ); /* 夜间模式全黑色 */
  color: #ffffff; /* 白色文字 */
}


.circle:hover {
  transform: scale(1.2); /* 鼠标悬停时放大 */
  transition: transform 0.3s ease; /* 平滑过渡 */
}

/* 环形图中的图标 */
.skill-icon {
  font-size: 2rem;
  color: #333; /* 图标颜色为黑色 */
  position: absolute;
  top: 50%; /* 垂直居中 */
  left: 50%; /* 水平居中 */
  transform: translate(-50%, -50%); /* 将图标中心点移到父容器中心 */
  background-color: #ffffff; /* 图标背景颜色 */
  width: 50px; /* 图标容器的宽度 */
  height: 50px; /* 图标容器的高度 */
  border-radius: 50%; /* 图标容器为圆形 */
  display: flex;
  align-items: center;
  justify-content: center; /* 确保图标在容器内也居中 */
}

body.dark-mode .skill-icon {
  background-color: #333; /* 图标背景颜色 */
  color: #ffffff; /* 白色文字 */
}


/* 技能名字 */
.circle span {
  position: absolute;
  bottom: -22px; /* 放置在环形图下方 */
  font-size: 0.9rem;
  color: #555; /* 灰色文字 */
}

body.dark-mode .circle span {
  color: #e0e0e0; /* 白色文字 */
}



/* 实验 */


#skill-radar {
  background: transparent;
  backdrop-filter: blur(4px);
  position: relative;
}

#stars-bg {
  position: absolute;
  top: 0; left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  z-index: 0;
  pointer-events: none;
}
/* 雷达图容器 */
.radar-container {
  position: relative;
  width: 500px;
  height: 500px;
  border-radius: 50%;
  background: radial-gradient(circle, 
    rgba(40,40,40,0.8) 0%,
    rgba(20,20,20,0.9) 100%
  );
  box-shadow: 0 0 30px rgba(255,100,100,0.2);
  margin: 0 auto;
}

.radar-ring {
  position: absolute;
  border: 1px solid rgba(255,100,100,0.3);
  border-radius: 50%;
  /* 精准居中定位 */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* 添加箭头装饰 */
.radar-line::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: -3px;
  width: 7px;
  height: 7px;
  background: #ff6464;
  border-radius: 50%;
  animation: linePulse 1.5s infinite;
}

@keyframes linePulse {
  0%, 100% { transform: scale(1); opacity: 0.8; }
  50% { transform: scale(1.3); opacity: 0.4; }
}


.ring1 { 
  width: 60%; 
  height: 60%;
  top: 50%;
  left: 50%;
}

.ring2 { 
  width: 80%; 
  height: 80%;
  top: 50%;
  left: 50%;
}



/* 分类标签 */
.dimension-label {
  position: absolute;
  font-size: 1.1rem;
  color: #ff6464;
  text-shadow: 0 0 8px rgba(255,100,100,0.3);
  transform: translate(-50%, -50%);
  white-space: nowrap;
  padding: 8px 12px;
  background: transparent;
  border-radius: 20px;
  backdrop-filter: blur(4px);
  transition: all 0.3s ease;
}

.dimension-label:hover {
  transform: translate(-50%, -50%) scale(1.1);
  box-shadow: 0 0 15px rgba(255,100,100,0.4);
}
/* 技能节点 */
.radar-skill {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.radar-skill i {
  font-size: 1.5rem;
  margin-bottom: 5px;
  text-shadow: 0 0 8px rgba(255,100,100,0.3);
}

.radar-skill:hover {
  transform: scale(1.15);
  filter: brightness(1.2);
}


.radar-skill i {
  color: white;
  filter: drop-shadow(0 0 8px rgba(255, 100, 100, 0.6));
}

.radar-container {

  background: rgba(255, 255, 255, 0.03);
  z-index: 1;
}

.radar-ring {
  position: absolute;
  border: 1.5px dashed rgba(255, 0, 0, 0.2);
  border-radius: 50%;
  box-shadow: 0 0 8px rgba(255, 0, 0, 0.3);
}
.radar-line {
  position: absolute;
  top: 0;
  left: 50%;
  width: 1px;
  height: 300px;
  background: rgba(255, 0, 0, 0.2);
  transform-origin: bottom center;  /* 以底部为原点旋转 */
  z-index: 0;
}



body.dark-mode .radar-ring {
  border: 1.5px dashed rgba(255, 100, 100, 0.4);
  box-shadow: 0 0 10px rgba(255, 0, 0, 0.5);
}


body.dark-mode .radar-line {
  background: rgba(255, 100, 100, 0.3);
}

@keyframes breath {
  0%, 100% {
    box-shadow: 0 0 8px rgba(255, 80, 80, 0.2);
  }
  50% {
    box-shadow: 0 0 16px rgba(255, 80, 80, 0.5);
  }
}
@keyframes core-glow {
  0%, 100% { box-shadow: 0 0 8px red; }
  50% { box-shadow: 0 0 18px red; }
}
.center-core {
  animation: core-glow 3s ease-in-out infinite;
}
.radar-skill:hover {
  transform: scale(1.2);
}
.radar-skill:hover i {
  transform: scale(1.4) rotate(8deg);
  background-color: rgba(255, 50, 50, 0.3);
  box-shadow: 0 0 20px rgba(255, 80, 80, 0.6);
}



/* 黑暗模式 label 更亮点 */
body.dark-mode .dimension-label {
  background: rgba(0, 0, 0, 0.6);
  color: #ff9999;
}


body.dark-mode .radar-skill span {
  color: #ffffff;
  text-shadow: 0 0 3px rgba(255, 255, 255, 0.3);
  font-weight: 500;
}

body:not(.dark-mode) .radar-skill span {
  color: #222;
  text-shadow: none;
}

/* 夜间模式 */
body.dark-mode .skill-block {
  background-color: rgba(255, 255, 255, 0.05);
  color: #e0e0e0;
}

body.dark-mode .skill-block p {
  color: #ccc;
}


/* 修复圆形变形的CSS */
.radar-container {
  position: relative;
  width: 500px;  /* 固定宽高为1:1 */
  height: 500px; /* 必须与width相等 */
  margin: 20px auto;
  padding : 20px;
  overflow: visible; /* 确保溢出部分可见 */
}

@media (max-width: 768px) {
  .radar-container  {
    max-width: 300px;
    max-height: 300px;
  }
}

.radar-ring {
  position: absolute;
  border: 1px solid rgba(255,100,100,0.3);
  border-radius: 50%;
  /* 精准居中定位 */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* 最外层圆形特别调整 */
.ring3 {
  width: 98%;   /* 略微缩小避免溢出 */
  height: 98%;  /* 必须与width相等 */
  box-shadow: 0 0 20px rgba(255,100,100,0.2); /* 添加发光效果 */
  border: 2px solid rgba(255,100,100,0.5); /* 加粗边框 */
}
/* 分割线容器 */
.radar-line-container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
}

/* 分割线样式 */
.radar-line {
  position: absolute;
  width: 1px;
  height: 50%; /* 精确等于半径长度 */
  bottom: 50%; /* 从圆心开始延伸 */
  left: 50%;
  background: linear-gradient(
    to bottom,
    rgba(255,100,100,0) 0%,
    rgba(255,100,100,0.4) 50%,
    rgba(255,100,100,0) 100%
  );
  transform-origin: bottom center; /* 以底部为旋转中心 */
  box-shadow: 0 0 8px rgba(255,100,100,0.2);
}



@keyframes linePulse {
  0%, 100% { transform: scale(1); opacity: 0.8; }
  50% { transform: scale(1.3); opacity: 0.4; }
}
/* ================ projects 部分样式 ============ */
  /* 更现代化的 details 分类卡片样式 */
  .project-category {
    background: linear-gradient(145deg, #fafafa, #f0f0f0);
    border-radius: 16px;
    box-shadow: 0 4px 12px rgba(0,0,0,0.08);
    margin: 1.5rem 0;
    padding: 0.5rem 1.2rem;
    transition: all 0.3s ease-in-out;
    border: 1px solid #ddd;
  }

  .project-category summary {
    cursor: pointer;
    font-size: 1.25rem;
    font-weight: 600;
    padding: 0.6rem 0;
    color: #222;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    list-style: none;
  }

  .project-category[open] {
    background: linear-gradient(145deg, #ffffff, #f2f2f2);
    transform: translateY(-2px);
    box-shadow: 0 6px 16px rgba(0,0,0,0.1);
  }

  .project-category[open] summary {
    border-bottom: 1px solid #ccc;
    margin-bottom: 1rem;
  }

  .project-category summary::-webkit-details-marker {
    display: none;
  }

  .project-category summary::before {
    content: "▶";
    display: inline-block;
    transform: rotate(0deg);
    transition: transform 0.3s ease;
    color: #888;
  }

  .project-category[open] summary::before {
    transform: rotate(90deg);
    content: "▼";
  }


  .badge {
    background: #eee;
    color: #555;
    padding: 0.15rem 0.4rem;
    font-size: 0.8rem;
    border-radius: 8px;
    margin-left: 0.5rem;
  }

  .project-buttons {
    display: flex;
    gap: 1rem;
    margin-top: 0.6rem;
  }

  .project-buttons .btn {
    background: #000;
    color: #fff;
    border-radius: 50%;
    padding: 0.6rem;
    width: 2.6rem;
    height: 2.6rem;
    text-align: center;
    font-size: 1.2rem;
    transition: transform 0.2s ease;
  }

  .project-buttons .btn:hover {
    transform: translateY(-4px) scale(1.1);
    background: #444;
  }
 


/* ====== 联系部分 ====== */
/* Contact Section */
#contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
}

h2 {
  margin-bottom: 20px;
}

/* 模拟浏览器窗口 */
.contact-browser {
  width: 85%;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

body.dark-mode .contact-browser {
  background-color: #2a2a2a;
  box-shadow: 0 4px 8px rgba(255, 0.2);
}

/* 浏览器顶部 */
.browser-header {
  height: 30px;
  background-color: #e0e0e0;
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-radius: 10px 10px 0 0;
}

body.dark-mode .browser-header {
  background-color: #333;
  color: #ffffff;
}

/* 浏览器按钮 */
.browser-buttons {
  display: flex;
  gap: 5px;
}

.browser-buttons span {
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.browser-buttons .red {
  background-color: #ff5f57;
}

.browser-buttons .yellow {
  background-color: #ffbd2e;
}

.browser-buttons .green {
  background-color: #28c840;
}

/* 浏览器内容区域 */
.browser-content {
  padding: 50px;
}

/* Contact 表单 */
.contact-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: calc(100% - 40px); /* 确保表单左右有适当的内边距 */
  max-width: 450px; /* 限制表单的最大宽度 */
  margin: 0 auto; /* 使表单居中 */

}

.contact-form label {
  font-weight: bold;
  font-size: 0.9rem;
  color: #333;
}

body.dark-mode .contact-form label {
  color: #ffffff; /* 白色文字 */
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 0.9rem;
}

.contact-form button {
  padding: 15px 0px;
  border: none;
  width: 30%;
  margin:0 auto;
  background-color: #4f4c4c;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-form button:hover {
  background-color: #010204;
}

body.dark-mode .contact-form button {
  background-color: #ffffff;
  color: #000000; /* 黑色文字 */
}

.contact-form button:hover {
  background-color: #747474;
}

/* ====== 回到顶部 ====== */
.scroll-top {
  position: fixed;
  bottom: 30px; /* 调整距离底部的位置 */
  left: 50px; /* 调整距离右边的位置 */
  z-index: 1000; /* 确保按钮在内容之上 */
  background: none; /* 移除背景颜色 */
  border: none; /* 无边框 */
  color: #fff; /* 图标颜色 */
  font-size: 2rem; /* 图标大小 */
  cursor: pointer; /* 鼠标样式 */
  visibility: hidden; /* 默认隐藏 */
  opacity: 0; /* 默认透明 */
  transition: opacity 0.3s ease, transform 0.3s ease; /* 添加过渡效果 */
}


/* 可见状态 */
.scroll-top.visible {
  visibility: visible;
  opacity: 1;
}

.scroll-top:before {
  content: '\f0c2'; /* Font Awesome 云朵图标 */
  font-family: 'Font Awesome 5 Free'; /* 使用 Font Awesome 字体 */
  font-weight: 900; /* 加粗 */
  font-size: 4rem; /* 云朵大小 */
  color: transparent; /* 清透白色 */
  position: absolute;
  top: -13px; /* 调整云朵相对按钮的位置 */
  left: -17px; /* 调整云朵相对按钮的位置 */
  z-index: -1; /* 确保云朵在箭头后面 */
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); /* 给云朵添加柔和阴影 */
}

body.dark-mode .scroll-top:before {
  color: rgba(0, 0, 0, 1); /* 黑色文字 */
}

/* 鼠标悬停效果 */
.scroll-top:hover {
  transform: scale(1.5); /* 放大效果 */
  transform: rotate(-30deg); /* 3D旋转 */
  transition: transform 0.3s ease; /* 平滑过渡 */
}

.scroll-top i {
  font-size: 2rem; /* 图标大小 */
  position: relative;
  z-index: 1; /* 确保箭头在云朵上方 */
  color: #6f6f6f; /* 向上的箭头颜色 */
}

body.dark-mode .scroll-top i {
  color: #e0e0e0; /* 白色文字 */
}




/* ====== 响应式 ====== */
/* 针对平板（768px 以下） */
@media screen and (max-width: 768px) {

  .container {
    width: 85%; /* 更窄的内容宽度 */
    padding: 10px; /* 减小内边距 */
  }
}

/* 针对手机（480px 以下） */
@media screen and (max-width: 480px) {

  .logo {
    width: 100px; /* 缩小 Logo */
    height: auto;
  }

  .resume-button {
    font-size: 0.9rem; /* 缩小 Resume 按钮文字 */
    padding: 10px 15px;
  }
  .contack-form button{
    width: 40%; /* 缩小按钮宽度 */
    font-size: 0.9rem; /* 缩小 Resume 按钮文字 */
  }
}


/* 汉堡菜单默认样式 */
.hamburger-menu {
  display: none; /* 默认隐藏，仅移动端显示 */
  background: none;
  border: none;
  font-size: 1.6rem;
  cursor: pointer;
  z-index: 1001;
  color: #333;
}

body.dark-mode .hamburger-menu {
  color: #fff;
}


/* 移动设备上显示汉堡菜单 */
@media screen and (max-width: 768px) {
.hamburger-menu {
    display: block;
    margin-right: 40px;
}
  .nav-links {
    display: none; /* 默认隐藏导航链接 */
    flex-direction: column;
    gap: 30px;
    background: transparent; /* 背景色 */
    position: absolute;
    top: 70px; /* 确保在导航栏下方显示 */
    right: 0px; /* 靠右显示 */
    padding: 20px;
    border-radius: 0px;
    border-left: 1px;
    border-left: #000;
    z-index: 1000;
    position:absolute;
  }
  body.dark-mode .nav-links {
    background: rgba(18, 18, 18, 0.95); /* 夜间模式背景 */
  }

  .nav-links.active {
    display: flex; /* 激活时显示链接 */
  }
}

/* 针对手机（480px 以下）的调整 */
@media screen and (max-width: 480px) {
  .scroll-top {
    bottom: 20px; /* 更靠近底部 */
    left:10px; /* 更靠近屏幕左侧 */
    font-size: 1.2rem; /* 缩小按钮大小 */
  }

  .scroll-top i {
    font-size: 1rem; /* 缩小箭头图标 */
  }
  .scroll-top:before {
  content: '\f0c2'; /* Font Awesome 云朵图标 */
  font-family: 'Font Awesome 5 Free'; /* 使用 Font Awesome 字体 */
  font-weight: 900; /* 加粗 */
  font-size: 3rem; /* 云朵大小 */
  color: transparent; /* 清透白色 */
  position: absolute;
  top: -13px; /* 调整云朵相对按钮的位置 */
  left: -17px; /* 调整云朵相对按钮的位置 */
  z-index: -1; /* 确保云朵在箭头后面 */
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); /* 给云朵添加柔和阴影 */
}
}



















/* 树形导航 */
.project-tree {
  list-style: none;
  padding: 0;
  margin: 0;
}

.tree-node, .tree-leaf {
  padding: 12px 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 8px;
  margin: 4px 0;
  display: flex;
  align-items: center;
  gap: 10px;
}

.tree-node {
  background: rgba(255,255,255,0.1);
  font-weight: 600;
}

.tree-leaf {
  padding-left: 40px;
  font-size: 0.9em;
  opacity: 0.8;
}

.tree-node:hover, .tree-leaf:hover {
  background: rgba(255,255,255,0.15);
}

.tree-node.active {
  background: var(--accent-color);
  color: white;
}

/* 项目内容区 */
.project-content {
  background: rgba(255,255,255,0.05);
  border-radius: 12px;
  padding: 2rem;
  min-height: 500px;
}

.tech-stack {
  padding: 12px 0;
  border-bottom: 1px solid rgba(255,255,255,0.1);
  margin-bottom: 1rem;
}

.feature-list {
  padding-left: 20px;
  margin: 1rem 0;
}

.feature-list li {
  margin-bottom: 8px;
  position: relative;
  padding-left: 15px;
}

.feature-list li::before {
  content: '▹';
  position: absolute;
  left: 0;
  color: var(--accent-color);
}

/* 响应式设计 */
@media (max-width: 768px) {
  .projects-container {
    grid-template-columns: 1fr;
  }
  
  .project-tree {
    max-height: 300px;
    overflow-y: auto;
  }
}
/* Swiper增强样式 */
.swiper-container {
  border-radius: 12px;
  overflow: hidden;
  margin: 1.5rem 0;
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
}

.swiper-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.swiper-pagination-bullet {
  background: #fff !important;
  opacity: 0.5 !important;
  transition: all 0.3s ease !important;
}

.swiper-pagination-bullet-active {
  opacity: 1 !important;
  background: var(--accent-color) !important;
  transform: scale(1.2);
}

.swiper-button-next,
.swiper-button-prev {
  color: var(--accent-color) !important;
}





/* 分类项目展示 */
.projects-section {
  padding: 4rem 1rem;
  max-width: 1200px;
  margin: 0 auto;
}

.section-title {
  text-align: center;
  margin-bottom: 3rem;
  color: var(--text-primary);
}

.projects-container {
  display: grid;
  grid-template-columns: 240px 1fr;
  width:900px;
}

.category-nav {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: sticky;
  top: 120px;
  height: fit-content;

}

.nav-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background: var(--bg-secondary);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.nav-item:hover {
  background: var(--bg-hover);
  transform: translateX(5px);
}

.nav-item.active {
  background: var(--bg-active);
  font-weight: 600;
}

.category-icon {
  font-size: 1.4rem;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
  padding: 0.5rem;
}

.project-card {
  background: var(--bg-secondary);
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0,0,0,0.05);
  width:500px;
}

.card-header {
  margin-bottom: 1rem;
}

.tag-group {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 0.75rem;
}

.tag {
  background: var(--tag-bg);
  color: var(--tag-text);
  padding: 0.25rem 0.75rem;
  border-radius: 20px;
  font-size: 0.85rem;
}

.project-image {
  margin: 1rem 0;
  border-radius: 8px;
  overflow: hidden;
}

.project-image img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.project-image:hover img {
  transform: scale(1.03);
}

.action-buttons {
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
}

.link-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1.25rem;
  border-radius: 6px;
  color: white;
  text-decoration: none;
  transition: opacity 0.3s ease;
}

.link-button:hover {
  opacity: 0.9;
}

@media (max-width: 768px) {
  .projects-container {
    grid-template-columns: 1fr;
    max-width: 300px;
    max-width: 300px;
  }
  
  .category-nav {
    position: static;
    flex-direction: row;
    overflow-x: auto;
    padding-bottom: 1rem;
  
    width:300px;
  }

  .nav-item {
    flex-shrink: 0;
  }
}



.swiper-image-container {
  position: relative;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  background: #f8fafc;
}

.swiper-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 1rem;
}

.swiper-pagination {
  position: relative !important;
  margin-top: 1rem;
}

.swiper-pagination-bullet {
  transition: transform 0.2s ease;
}

.swiper-pagination-bullet:hover {
  transform: scale(1.2);
}

.status-badge {
  font-size: 0.8rem;
  padding: 0.25rem 0.75rem;
  margin-left: 1rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 999px;
  vertical-align: middle;
}



.custom-pagination {
  position: relative;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  gap: 8px;
}

.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  background: #e5e7eb;
  opacity: 0.6;
  transition: all 0.3s ease;
  border-radius: 4px;
}

.swiper-pagination-bullet-active {
  width: 24px;
  background: currentColor !important;
  opacity: 1 !important;
}

.action-buttons {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 1rem;
  margin-top: 1.5rem;
}

.project-link {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px 16px;
  border-radius: 8px;
  color: white;
  text-decoration: none;
  transition: all 0.3s ease;
  backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.project-link:hover {
  background: var(--hover-bg) !important;
  transform: translateY(-2px);
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
}

.status-badge {
  font-size: 0.8rem;
  padding: 0.25rem 0.75rem;
  margin-left: 1rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 999px;
  vertical-align: middle;
}
/* 在CSS中添加 */
.project-carousel {
  margin: 1rem 0;
  border-radius: 8px;
  overflow: hidden;
}

.swiper-image {
  width: 100%;
  height: 300px;
  object-fit: contain;
  background: #f5f5f5;
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-wrapper {
  transition-timing-function: linear;
}


.swiper-wrapper-project {
  display: flex;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.swiper-slide-project {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
}



.custom-pagination {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 8px;
  z-index: 10;
}

.custom-pagination .swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  background: #e5e7eb;
  opacity: 0.6;
  transition: all 0.3s ease;
  border-radius: 4px;
}

.custom-pagination .swiper-pagination-bullet-active {
  width: 24px;
  background: currentColor;
  opacity: 1;
}
/* 修改项目网格布局 */
.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

/* Fullstack项目独占一行 */
.projects-grid .project-card[data-category="fullstack"] {
  grid-column: 1 / -1;
  max-width: 100%;
  margin: 0 auto;
}

.swiper-wrapper {
  display: flex;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
}



.custom-pagination {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 8px;
  z-index: 10;
}

.custom-pagination .swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  background: #e5e7eb;
  opacity: 0.6;
  transition: all 0.3s ease;
  border-radius: 4px;
}

.custom-pagination .swiper-pagination-bullet-active {
  width: 24px;
  background: currentColor;
  opacity: 1;
}


.project-card.swiper-media {
  object-fit: cover;
}



/* 分页器定位修正 */
.custom-pagination {
  bottom: -25px !important; /* 调整分页器位置 */
  position: absolute;
  z-index: 10;
}




.custom-pagination {
  bottom: 20px !important; /* 调整分页器位置 */
  transform: translateX(-50%) !important;
  z-index: 10;
}

/* 新增媒体查询 */
@media (max-width: 768px) {
  .project-swiper {
    height: 300px !important;
  }
}

/* ====== 在style.css中添加 ====== */
/* 修复Swiper核心样式 */
.project-swiper {
  height: 500px; /* 设置明确高度 */
  position: relative;
  margin: 1rem 0;
  border-radius: 12px;
  overflow: hidden;
}

.swiper-media-container {
  height: 100%;
  background: #f8fafc;
}

.swiper-media {
  object-fit: cover; /* 确保图片填充 */
  width: 100%;
  height: 100%;
}

/* 分页器精确定位 */
.custom-pagination {
  bottom: 20px !important;
  transform: translateX(-50%);
  gap: 8px;
}

/* 移动端适配 */
@media (max-width: 768px) {
  .project-swiper {
    height: 300px;
    margin: 0.5rem 0;
  }
}

.swiper-pagination {
  bottom: 8px !important; /* 原本默认可能是 0 或更下 */
}
/* 💡 让 Swiper 分页器的小圆点更明显 */
.swiper-pagination-bullet {
  background: #888 !important; /* 默认颜色加深一点 */
  opacity: 0.9 !important;     /* 增加不透明度 */
  width: 10px;
  height: 10px;

  transition: all 0.3s ease;
  border-radius: 50%;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2); /* 增加轻微阴影增强对比 */
}

.swiper-pagination-bullet-active {
  background: #111 !important; /* 激活状态为黑色（清晰） */
  width: 14px;
  height: 14px;
  transform: scale(1.3);
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
}

/* 🌙 夜间模式优化：背景色和 bullet 色反转 */
body.dark-mode .swiper-pagination-bullet {
  background: #ccc !important;
  opacity: 0.9 !important;
}

body.dark-mode .swiper-pagination-bullet-active {
  background: #fff !important;
}




.success-message {
  color: #28a745;
  margin-top: 1rem;
}

.error-message {
  color: #dc3545;
  margin-top: 1rem;
}

button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
/* style.css 新增部分 */
.success-message {
  color: #28a745;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 8px;
}

.error-message {
  color: #dc3545;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 8px;
}

button.loading {
  opacity: 0.7;
  cursor: not-allowed;
}

.fa-spinner {
  animation: fa-spin 1s infinite linear;
}

@keyframes fa-spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}






.edu-wrapper {
  display: flex;
    flex-direction: column;
    align-items: center;
  align-items: center;
  gap: 40px;
  margin-top: 70px;
}

.edu-wrapper .side {
  flex: 1;
  min-width: 300px;
}

.edu-cards {
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 0px;
  margin: 0px;
  max-width:900px;
}
.edu-card {
 background: rgba(255, 255, 255, 0.1); /* 半透明背景 */
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 0.8rem;
  width: fit-content;
  min-width: 250px;
  box-shadow: none;
  transition: transform 0.2s ease;
  padding:20px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3), 0 8px 24px rgba(0, 0, 0, 0.2); /* 深化阴影 */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* 平滑过渡 */
}
.edu-card:hover {
  transform: scale(1.02);
  border-color: #aaa;
}


/* 夜间模式技能卡片 */
body.dark-mode .edu-card {
  background: rgba(255, 255, 255, 0.05); /* 更深的透明背景 */
  color: #ffffff; /* 白色文字 */
  box-shadow: 0 4px 10px rgba(255, 255, 255, 0.2); /* 浅色阴影 */
}


.edu-card:hover {
  transform: scale(1.05) rotate(1deg); /* 放大并旋转 */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3); /* 阴影增加 */
}

@media screen and (max-width: 768px) {
  .edu-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .edu-cards {
    flex-direction: column;
  }
}
.github-heatmap {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;
  margin-left: 90px;
}
.main-wrapper {
  max-width: 1100px;
  margin: 0 auto;     /* 💡 自动居中对齐 */
  padding: 0 20px;    /* 💡 两边内边距，防止靠边贴太死 */
}
@media screen and (max-width: 768px) {
  .main-wrapper {
    padding: 0 12px;
  }
}
.container {
  margin: 60px auto; /* ✅ 修改：左右自动居中 */
  padding: 20px;
}
.skills {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr)); /* ✅ 自适应栅格 */
  justify-items: center; /* ✅ 内容居中 */
  width: 100%;
  max-width: 900px;
  margin: 0 auto; /* ✅ 水平居中 */
}
.edu-cards {
  width: 100%;         /* ✅ 宽度撑满 */
  max-width: 900px;
  margin: 0 auto;       /* ✅ 居中 */
  flex-wrap: wrap;      /* ✅ 允许换行 */
  justify-content: center; /* ✅ 居中对齐内容 */
}
.projects-grid {
  width: 100%;              /* ✅ 防止溢出 */
  max-width: 1100px;        /* ✅ 设置最大宽度 */
  margin: 0 auto;           /* ✅ 居中 */
  padding: 0 1rem;          /* ✅ 添加左右内边距 */
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0; /* ✅ 先清除默认 padding，避免意外偏移 */
}
.center-container {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
}
/* ✅ 父容器：强制所有页面元素居中 */
.container, .main-wrapper, .center-container, .bio-with-photo, .edu-wrapper, .github-heatmap {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  padding: 0 30px;
  box-sizing: border-box;
}

/* ✅ 所有横向内容组：flex-wrap & 居中对齐 */
.bio-with-photo,
.edu-cards,
.skills,
.stage-track,
.edu-wrapper,
.projects-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
}

/* ✅ 图像和照片：百分比宽度适配小屏 */
.bio-photo img, .profile-image img {
  width: 100%;
  max-width: 250px;
  height: auto;
  border-radius: 12px;
  object-fit: cover;
}

/* ✅ 修复 GitHub 贡献图表错位 */
.github-heatmap {
  margin: 40px auto;
  justify-content: center;
  width: 100%;
}

/* ✅ 时间线卡片适配移动端 */
.edu-card {
  width: 100%;
  max-width: 350px;
  margin: 10px auto;
}

/* ✅ 阶段进程盒子对齐 */
.stage-box {
  min-width: 80px;
  text-align: center;
}

/* ✅ 全局 img */
img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}
body {
  padding: 0;
  margin: 0;
}



body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  background-color: var(--bg-color);
  color: var(--text-color);
  line-height: 1.6;
  box-sizing: border-box;
}

@media screen and (max-width: 480px) {
  .body {
    margin-left:100px;
  }

  .skills, .edu-cards,  {
    flex-direction: column;
    align-items: center;
  }
.projects-grid {
    width:300px;
    margin: 0 auto;
  }
  .project-card,
   .edu-card {
    width: 80%;
    max-width: 95%;
  }
}

/* 根布局修正，保证 app 居中、响应式 */
body,
#root {
  width: 100%;
  display: flex;
  justify-content: center;
}


/* 🍭 Swiper 调整 */
.project-swiper img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
}
#root {
  width: 100%;
  max-width: 100vw;      /* 限制最大宽度不能超出屏幕 */
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;   /* 居中内容 */
}

